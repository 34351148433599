export const MenuItems = [
  {
    title: 'Dashboard',
    url: '/Dash',
    cName: 'nav-links'
  },
  {
    title: 'Beecons',
    url: '/Home',
    cName: 'nav-links'
  }  
]