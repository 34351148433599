export const ItemOpciones = [
  {
    title: 'Home',
    url: '/beecons',
    cName: 'nav-links'
  },
  {
    title: 'Beecons',
    url: '/home',
    cName: 'nav-links'
  }  
]