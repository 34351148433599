import React, { Component } from 'react'
import { ItemOpciones } from './ItemOpciones';
import Cookies from 'universal-cookie/es6';
import "./MenuS.css";

const cookies = new Cookies();

export default class MenuS extends Component {
  constructor(){
    super();
    this.state = {

    }
  }  
  GoPage(url){
    if(url==="/"){
      cookies.remove('nombre',{path: '/'});
      cookies.remove('email',{path: '/'});
    }
    window.location.href=url;
  }
  render() {
    return (
      <div id="master" className="Contenedor">
        <div id="elem1">
          <img  className="logo-iconn" alt="logoicon" src="/LogoComercios/logo-beekon.png"></img>
        </div>                
        <nav id="elem2">
          <ul>
            {ItemOpciones.map((item, index) => {
              return(
                <li key={index}>
                  <a onClick= { () => this.GoPage(item.url) } >
                    {item.title}
                  </a>
                </li>              
              )
            })}    
          </ul>
        </nav>
      </div>
    )
  }
}
