import React, { Component } from 'react';
import Cookies from 'universal-cookie/es6';
import Navi from "../componentes/Navbar/Navi";
import '../css/Home.css';
import MenuS from '../componentes/MenuLateral/MenuS';
import { Link } from 'react-router-dom';

const cookies = new Cookies();

class Home extends Component {
    constructor(){
      super();
      this.state = {
        lista: [],
        colorB: false
      };
    }    
    UNSAFE_componentWillMount() {      
      if(cookies.get('nombre',{path: '/'}) === undefined || cookies.get('email',{path: '/'}) === undefined){     
        window.location.href="/";  
      }     
      this.ListaB();
    }   
    async ListaB () {
      const url = "Apis/DevicesList.php";
      await fetch(url)
      .then(response => response.json())
      .then((Rjson) => {         
          /*for (let index = 0; index < Rjson.length; index++) {            
            this.state.lista.push(Rjson[index]);
          }*/
          this.setState({lista: Rjson});
          console.log(this.state.lista);
      })
      .catch((error) => {
          console.log(error);          
      });     
    }
    GoPage(url){
      if(url==="/"){
        cookies.remove('nombre',{path: '/'});
        cookies.remove('email',{path: '/'});
      }
      window.location.href=url;
    }    
    render() {
        return (
          <section id="pagina" >
            <div id="b1">
                <Navi />
            </div>
            <div id="b2">
                <MenuS /> 
            </div>
            <div id="b3">             
                <h3>Beecons</h3>                                                        
                <table className="table">
                  <thead>
                    <tr>               
                      <th scope="col">Beecons</th>     
                      <th scope="col">ID</th>
                      <th scope="col">Identi</th>
                      <th scope="col">Color</th>
                      <th scope="col">Location</th>
                      <th scope="col">Detalles</th>
                    </tr>
                  </thead>
                  <tbody className="bodytabla">                    
                      {this.state.lista.map((item,index) => {
                        return(
                          <tr key={index} className={ index%2 ? "tr-blanco": "tr-azul"}>
                            <td > <img className="imgT" alt="d" src={"/beecons/"+item.color+".png"}/></td>
                            <td >{item.id}</td>
                            <td >{item.identi}</td>
                            <td >{item.color}</td>
                            <td >{item.location}</td>
                            <td >
                              <Link
                                to={`/device`}
                                state = {{
                                  color: item.color,
                                  identi : item.identi,
                                  Lo : item.location,
                                  id : item.id,                                  
                                }}
                              >
                                  <i className={"far fa-clipboard fa-2x"}></i>
                              </Link>
                            </td>
                          </tr> 
                        ) 
                      })}                                 
                  </tbody>
                </table>
              
            </div>
            <div id="b4">
                <footer>Footer</footer>
            </div>                               
          </section>            
        );
    }
}
export default Home;