import React from 'react';
import { useEffect,useState } from 'react';
import Navi from '../componentes/Navbar/Navi';
import MenuS from '../componentes/MenuLateral/MenuS';
import Cookies from 'universal-cookie/es6';
import { useLocation } from 'react-router';
import "../css/deviceB.css"

const cookies = new Cookies();

function DeviceB() {    
  const location = useLocation()  
  const { color,id,identi,Lo } = location.state 
  const [attach,setAttach] = useState([]);
  const [idattach,setIdattach] = useState(0);
  const [llaves,setLlaves] = useState([]);
  const [cargar,setCargar] = useState(false);
  
  async function UPAttach() {    
    const url = "Apis/UpdateAttach.php";
    await fetch(url,{           
      method: 'POST',
      headers:{
          Accept: 'application/json',
          'Content-Type': 'application/json',                           
      },
      body: JSON.stringify({
          idattach: idattach,
          attach: attach,
          llaves: llaves            
      })
    })
    .then(response => response.json())
    .then((Rjson) => {                      
        console.log(Rjson);               
    })
    .catch((error) => {
        console.log(error);          
    });     
  }
  async function Attaches() {    
    const url = "Apis/GetAttachmentsOne.php";
    await fetch(url,{           
      method: 'POST',
      headers:{
          Accept: 'application/json',
          'Content-Type': 'application/json',                           
      },
      body: JSON.stringify({
          identi: identi   
      })
    })
    .then(response => response.json())
    .then((Rjson) => {         
        /*for (let index = 0; index < Rjson.length; index++) {            
          this.state.lista.push(Rjson[index]);
        }*/        
        //console.log(Rjson);
        setAttach(Rjson.payload);
        setIdattach(Rjson.id)
        setLlaves(Object.keys(Rjson.payload));
        console.log(Rjson);
        setCargar(true);
        
    })
    .catch((error) => {
        console.log(error);          
    });     
  }
  useEffect(() =>{
    Attaches();
  },[]);
  const updateFieldChanged = index => e => {
    console.log('index: ' + index);
    console.log('property name: '+ e.target.name);
    let newArr = [...llaves]; 
    newArr[index] = e.target.value;   
    setLlaves(newArr);
  }
  if(cargar){        
    return (
      <section id="pagina" >
        <div id="b1">
            <Navi />
        </div>
        <div id="b2">
            <MenuS /> 
        </div>
        <div id="b3" className="Contenido">
            <div id="beecon" className="p-beecons-style">   
              <div>
                <img alt="d" src={"/beecons/"+color+".png"}/>
              </div>              
              <div >
                <p >Color: {color}</p>  
              </div>  
              <div >
                <p>ID: {id}</p>  
              </div >
              <div >
                <p>Identi: {identi}</p>  
              </div >                                                            
            </div>
            <div id="informacion">
            <div >
                <p >Color: {color}</p>  
              </div>  
              <div >
                <p>ID: {id}</p>  
              </div >
              <div >
                <p>Identi: {identi}</p>  
              </div >
            </div>            
            <div id="adjuntos">
              <p>Adjuntos</p>
              <div className="box-adjuntos">   
                <table>
                  <thead>
                    <tr>
                      <th>Key</th>
                      <th>Mensaje</th>
                    </tr>
                  </thead>
                  <tbody>                                          
                      {                  
                        llaves.map((Element,index) => {                    
                          return(
                            <tr key={index}>
                              <td>
                                <input
                                  name="llave"                                 
                                  value={Element} 
                                  onChange={updateFieldChanged(index)}/>
                              </td>
                              <td>
                                <input  
                                  name="texto"                               
                                  value={attach[Element]} 
                                  onChange={input => setAttach({...attach,[Element]:input.target.value})}/>
                              </td>                              
                            </tr>
                          );                    
                        })
                      }                                         
                  </tbody>
                </table>                                                                                                                                          
              </div>
              <button onClick={ () => UPAttach()}>
                  Actualizar
              </button>
            </div>
        </div>
        <div id="b4">
            <footer>Footer</footer>
        </div>                               
      </section>   
    );  
  }
  else{
    return(
      <div>
        Vacio
      </div>
    );
  }
    
}
export default DeviceB;