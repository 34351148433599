import React, {Component} from "react";
import '../css/Login.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Cookies from "universal-cookie/es6";


const cookies = new Cookies();

class Login extends Component {
    constructor(){
        super();
        this.state = {               
                username: "",
                password: "",                                          
        };
        this.handleChange = this.handleChange.bind(this);       
        //this.handleSubmit = this.handleSubmit.bind(this);
    }             
    handleChange(event){
        if(event.target.name==="username"){
            this.setState({username: event.target.value});
        }
        else{
            this.setState({password: event.target.value});
        }        
    }
    async checklogin(){        
        const url = "/Apis/checklogin.php";
        await fetch(url,{           
            method: 'POST',
            headers:{
                Accept: 'application/json',
                'Content-Type': 'application/json',                           
            },
            body: JSON.stringify({
                username: this.state.username,
                password: this.state.password
            })
        })
        .then(response => response.json())
        .then((Rjson) => {
            if(Rjson[0].nombre!=="vacio"){
                cookies.set('nombre',Rjson[0].nombre,{path: '/'});
                cookies.set('email',Rjson[0].email,{path: '/'});
                window.location.href="/Home";               
            }
            else{
                alert("fallo");
            }            
        })
        .catch((error) => {
            console.log(error);
        });
    }

    render(){
        return(
            <div className="containerPrincipal">
                <div className="containerSecundario">
                    <div className="form-group">
                        <label>Usuario: </label>
                        <br/>
                        <input type="text" className="form-control " 
                            name="username" value={this.state.username} onChange={this.handleChange}/>
                        <br/>
                        <label>Contraseña: </label>
                        <br/>
                        <input type="password" className="form-control"
                            name="password" value={this.state.password} onChange={this.handleChange}/>
                        <br/>                        
                        <button className="btn btn-primary" 
                            onClick = { () => this.checklogin() }>
                            Iniciar Sesion
                        </button>                       
                    </div>
                </div>
            </div>
        );
    }
}
export default Login;