import React, { Component } from 'react';
import Navi from '../componentes/Navbar/Navi';
import MenuS from '../componentes/MenuLateral/MenuS';

class Dashboard extends Component {

  render() {
    return (
      <section id="pagina" >
        <div id="b1">
            <Navi />
        </div>
        <div id="b2">
            <MenuS /> 
        </div>
        <div id="b3">
            <main>
              Hola
            </main>
        </div>
        <div id="b4">
            <footer>Footer</footer>
        </div>                               
      </section>
      /*<div>
        <section id="page">
          <div id="b3">Main area</div>          
          <div id="b2">Navigation</div>
          <div id="b1">Header</div>
          <div id="b4">
            <div id="z1">Z1
              <div> dsdsd
                <div>
                <i className="fas fa-address-card"> Perfil</i>
                </div>
              </div>
            </div>              
            <div id="z2">Z2</div>
            <div id="z3">Z3</div>
            <div id="z4">Z4</div>           
          </div>
        </section>
      </div>*/
      
    );
  }
}

export default Dashboard;