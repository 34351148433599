import React from 'react';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import Home from '../pages/Home';
import Login from '../pages/Login';
import Dashboard from '../pages/Dashboard';
import DeviceB from '../pages/deviceB';
import "../css/Global.css"

function Rutas() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path = "/" element={<Login/>}/>
        <Route path = "/home" element={<Home/>}/>
        <Route path = "/dashboard" element={<Dashboard/>}/>
        <Route path = "/device" element={<DeviceB/>}/>
      </Routes>
    </BrowserRouter>
  );
}
 
export default Rutas;
