import React from 'react';
import ReactDOM from 'react-dom';
import Rutas from './routes/Rutas';


ReactDOM.render( 
    <Rutas/>,  
  document.getElementById('root')
);


