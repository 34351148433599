import React, { Component } from 'react';
import './Navi.css';
import Logo from "../../assets/logo.png"; 
import Cookies from 'universal-cookie/es6';
import { MenuItems } from './MenuItems';


const cookies = new Cookies();

console.log(MenuItems);

class Navi extends Component{
  constructor(props){
    super(props);
    this.state = {
      op: false,
      menu: false,
      pantalla: 0,
    };
  }
  
  handleClick = () => {
    this.setState({ menu: !this.state.menu })
  }
  handlePerfil = () => {
    this.setState({ op: !this.state.op })
  }
  GoPage(url){
      if(url==="/"){
        cookies.remove('nombre',{path: '/'});
        cookies.remove('email',{path: '/'});
      }
      window.location.href=url;
  }
  render(){
    return(
      <div id="masterN">        
        <div className="NavbarItems" id="elem1">         
            <img className="navbar-logo" alt="navbarLogo"src={Logo} />                                                              
        </div>
        <div className="perfil" id="elem2">                        
            <i className={'fas fa-user-circle fa-2x'} onClick={this.handlePerfil}/>         
        </div>
        <div className="ItemsPerfil" id="elem3" >
          <div className={this.state.op ? 'perfil-op active': 'perfil-op'}>
            <button className="btn-navi">
              <i className="fas fa-address-card"> Perfil</i> 
            </button>          
            <button className="btn-navi" onClick={() => this.GoPage("/")}>
              <i className="fas fa-sign-in-alt"> Salir</i>  
            </button>
          </div>
        </div>                            
      </div>      
    )
  }
}
export default Navi;
